import './AnimatedBg.css'

export default function AnimatedBg() {
  return (
    <>
      <svg
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 10 100 80"
        id="animated-bg"
      >
        <path
          fill="#FF6201"
          className="out-top"
          d="M42,5C28.1-5.7,7.7-9.1-7.2,0C-22.5,12.8-27.7,41.1-13.8,59c15.5,21.5,52.6,22,67.2,2.3C64.4,45,58.7,18.5,42,5Z"
        />
        <path
          fill="#FF9501"
          className="in-top"
          d="M25.6,14.1C16.6,11.5,2.1,12.5-3,21.2C-11.3,33.1-3.2,55.6,12.4,60S47.1,48.9,46,34.5C45.2,24.1,34.4,16.6,25.6,14.1Z"
        />
        <path
          fill="#000"
          className="out-bottom"
          d="M110.9,58.6c-12.4-8.2-29.3-4.8-39.4,0.8c-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C122.6,73.3,119.7,64.3,110.9,58.6Z"
        />
        <path
          fill="#FF401C"
          className="in-bottom"
          d="M107,77.1c-9.6-6.1-22-3.1-29.5,2c-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C120.5,102.6,118.9,84.6,107,77.1Z"
        />
      </svg>
      <div className="filter-noise" />
    </>
  )
}
