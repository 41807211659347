import MetricsContextProvider from 'src/context/MetricsContext'
import { HeaderMetrics } from '@components/Header/CommonHeader/Header'
import { useMenu } from '@hooks/useMenu'
import { Outlet } from 'react-router-dom'
import Metrics from '@components/Header/Menu/Metrics'
import { useMetrics } from '@hooks/useMetrics'
import Common from '@components/Header/Menu/Common'
import { FormEvent, useRef, useState } from 'react'
import { useRefreshKpis, useUpdateKpis } from '@hooks/useApi'

function HeaderElementWrapper() {
  const { resetPendingMetrics, pendingMetrics } = useMetrics()
  const refresh = useRefreshKpis()
  const updateKpis = useUpdateKpis()
  const { openMenu, setOpenMenu } = useMenu()

  const [reset, setReset] = useState<number>(0)

  const formRef = useRef<HTMLFormElement>()

  const pendignElements = Object.keys(pendingMetrics).length === 0

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const metrics = Object.values(pendingMetrics)
    await updateKpis(metrics)
    refresh()
  }

  const handleReset = () => {
    setReset((prev) => prev + 1)
    resetPendingMetrics()
  }

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className={`wrapper ${openMenu ? 'open' : 'closed'}`}
    >
      <HeaderMetrics
        isPending={!pendignElements}
        handleMenu={setOpenMenu}
        handleResetMetrics={handleReset}
        openMenu={openMenu}
        element={pendignElements ? <Common /> : <Metrics />}
      />
      <Outlet context={{ reset }} />
    </form>
  )
}

export default function DashboardLayout() {
  return (
    <MetricsContextProvider>
      <HeaderElementWrapper />
    </MetricsContextProvider>
  )
}
