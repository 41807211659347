import { Helmet } from 'react-helmet-async'

export default function Meta() {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <title>Fundilusa Hub</title>
    </Helmet>
  )
}
