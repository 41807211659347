import { useContext, useState } from 'react'
import { FiltersContext } from 'src/context/FiltersContext'

export function useFilters() {
  const filters = useContext(FiltersContext)
  return filters
}

interface Args {
  category?: string
  subcategories?: string[]
  showAllInitial?: boolean
}

export function useFiltersData({
  category = null,
  subcategories = [],
  showAllInitial = true
}: Args = {}) {
  const [selectedCategory, setSelectedCategory] = useState<string>(category)
  const [selectedSubcategories, setSelectedSubcategories] =
    useState<string[]>(subcategories)
  const [showAll, setShowAll] = useState<boolean>(showAllInitial)

  const changeSelectedSubcategories = (subcategories: string[]) =>
    setSelectedSubcategories(subcategories)

  const changeSelectedCategory = (newCategory: string) => {
    setSelectedCategory((prev) => (prev === newCategory ? null : newCategory))
  }

  const handleSelectedSubcategory = (subcategory: string) =>
    setSelectedSubcategories((prev) =>
      prev.includes(subcategory)
        ? prev.filter((item) => item !== subcategory)
        : [...prev, subcategory]
    )

  const toggleShow = () => setShowAll((prev) => !prev)

  return {
    selectedCategory,
    selectedSubcategories,
    showAll,
    toggleShow,
    changeSelectedCategory,
    changeSelectedSubcategories,
    handleSelectedSubcategory
  }
}
