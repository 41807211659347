import './Toggle.css'

interface ToggleProps {
  value: boolean
  handleChange: () => void
}

export default function Toggle({ value, handleChange }: ToggleProps) {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={handleChange} />
      <span className="slider" />
    </label>
  )
}
