import { Icons } from '@static/icons'
import { useMetrics } from '@hooks/useMetrics'
import timeUtils from '@utils/time'
import {
  MouseEvent,
  Suspense,
  TouchEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import Weeks from '../Weeks/Weeks'
import Months from '../Months/Months'
import WeeksFallback from '../Weeks/WeeksFallback'
import MonthsFallback from '../Months/MonthsFallback'

import './PillsContainer.css'

const GAP = 140
const SHOWED_ITEMS = 3

const { calendarIcon, weekIcon } = Icons

export default function PillsContainer() {
  const sectionRef = useRef<HTMLElement>()
  const [dragging, setDragging] = useState<boolean>(false)

  const [initialCenter, initialRadius] =
    timeUtils.initializeRangeWeeks(SHOWED_ITEMS)

  const [[center, radius], setRange] = useState<[number, number]>([
    initialCenter,
    initialRadius
  ])

  const rangeRecord = useRef<{ x: number; center: number }>({
    center: center,
    x: 0
  })

  const { changeSlot, slot, changeFreq } = useMetrics()
  const isWeekly = slot.frequency === 'weekly'

  const msPerPx = (isWeekly ? 7 : 30) * 600000

  useEffect(() => {
    setRange((prev) => {
      const newRange = isWeekly
        ? timeUtils.initializeRangeWeeks(SHOWED_ITEMS, new Date(prev[0]))
        : timeUtils.initializeRangeMonths(SHOWED_ITEMS, new Date(prev[0]))

      return newRange
    })
  }, [isWeekly])

  const handleTouchStart = (e: TouchEvent | MouseEvent) => {
    setDragging(true)
    rangeRecord.current = {
      center,
      x: 'touches' in e ? e.targetTouches[0].pageX : e.pageX
    }
  }

  const handleTouchMove = (e: TouchEvent | MouseEvent) => {
    if (!dragging) return
    const x = 'touches' in e ? e.touches[0].pageX : e.pageX
    const walk = rangeRecord.current.x - x

    const msPerPixel = radius / GAP

    const val = walk * msPerPixel

    const prev = rangeRecord.current.center + val

    setRange([prev, radius])
  }

  const handleEnd = () => setDragging(false)

  const handleChange = () => {
    const actual = new Date(center)
    const value = !isWeekly
      ? timeUtils.getWeekNumber(actual)
      : timeUtils.getMonthNumber(actual)

    changeSlot({
      frequency: isWeekly ? 'monthly' : 'weekly',
      value,
      year: actual.getFullYear()
    })

    changeFreq()
    const [newCenter, newRadius] = isWeekly
      ? timeUtils.initializeRangeMonths(SHOWED_ITEMS, actual)
      : timeUtils.initializeRangeWeeks(SHOWED_ITEMS, actual)
    setRange([newCenter, newRadius])
  }

  const changeCenter = (center: number) => setRange((prev) => [center, prev[1]])

  return (
    <section
      className={`pills ${dragging ? 'dragging' : ''}`}
      ref={sectionRef}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleEnd}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      onMouseUp={handleEnd}
    >
      <div className="switch-freq">
        <button className="calendar" type="button" onClick={handleChange}>
          {slot.frequency === 'monthly' ? (
            <img src={calendarIcon.source} alt={calendarIcon.alt} />
          ) : (
            <img src={weekIcon.source} alt={weekIcon.alt} />
          )}
        </button>
      </div>
      {isWeekly ? (
        <Suspense
          fallback={
            <WeeksFallback
              msPerPx={msPerPx}
              middle={center}
              radius={radius}
              changeRange={changeCenter}
            />
          }
        >
          <Weeks
            msPerPx={msPerPx}
            middle={center}
            radius={radius}
            changeRange={changeCenter}
          />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <MonthsFallback
              msPerPx={msPerPx}
              middle={center}
              radius={radius}
              changeRange={changeCenter}
            />
          }
        >
          <Months
            msPerPx={msPerPx}
            middle={center}
            radius={radius}
            changeRange={changeCenter}
          />
        </Suspense>
      )}
    </section>
  )
}
