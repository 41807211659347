import Header from '@components/Header/CommonHeader/Header'

import { useMenu } from '@hooks/useMenu'
import { Outlet } from 'react-router-dom'

export default function Layout() {
  const { openMenu, setOpenMenu } = useMenu()
  return (
    <div className={`wrapper ${openMenu ? 'open' : 'closed'}`}>
      <Header handleMenu={setOpenMenu} openMenu={openMenu} />
      <Outlet />
    </div>
  )
}
