import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage/SelectLanguage'
import { Icons } from '@static/icons'

import './Menu.css'

interface MenuProps {
  open: boolean
}

export default function Menu({ open }: MenuProps) {
  return (
    <div id="menu" className={open ? 'open' : 'closed'}>
      <menu>
        <li>
          <Link to="/">
            <FormattedMessage id="dashboard.title" />
          </Link>
        </li>
        <li>
          <Link to="/overview">
            <FormattedMessage id="overview.title" />
          </Link>
        </li>

        <li>
          <SelectLanguage />
        </li>
      </menu>
      <button>
        <FormattedMessage id="menu.logout" />
        <img src={Icons.logoutIcon.source} alt={Icons.logoutIcon.alt} />
      </button>
    </div>
  )
}
