import { CompleteKpi } from 'src/types'
import timeUtils from './time'

const TOLERANCE_RANGE = 0.1

function applyCategory(selectedCategory: string, kpis: CompleteKpi[]) {
  return kpis.filter((kpi) => kpi.category === selectedCategory)
}

function applySubcategories(subcategories: string[], kpis: CompleteKpi[]) {
  return kpis.filter((kpi) => subcategories.includes(kpi.subcategory))
}

function showAlerts(kpis: CompleteKpi[]) {
  const actual = new Date()
  const year = actual.getFullYear()
  const week = timeUtils.getWeekByMs(actual.getTime())
  const month = timeUtils.getMonthNumber(actual)

  return kpis.filter(({ kpiFrequency, history, target: kpiTarget }) => {
    const isMonthly = kpiFrequency === 'monthly'
    const labelSlot = isMonthly ? 'M' : 'W'
    const valueSlot = isMonthly ? month : week
    const slotPeriod = `${year}-${labelSlot}${valueSlot}`

    const lastElement = history.at(-1)
    if (!lastElement) return false

    const { slot, value, targetValue } = lastElement
    if (slot !== slotPeriod) return false

    const target = targetValue ?? kpiTarget
    const minAcceptable = target * (1 - TOLERANCE_RANGE)
    const maxAcceptable = target * (1 + TOLERANCE_RANGE)

    return value < minAcceptable || value > maxAcceptable
  })
}

function applyFilters(
  {
    selectedCategory,
    selectedSubcategories,
    showAll
  }: {
    selectedCategory: string
    selectedSubcategories: string[]
    showAll: boolean
  },
  kpis: CompleteKpi[]
) {
  if (selectedCategory === null) return kpis

  const firstStepKpis = !showAll ? showAlerts(kpis) : kpis
  const appliedCategories = applyCategory(selectedCategory, firstStepKpis)

  return selectedSubcategories.length
    ? applySubcategories(selectedSubcategories, appliedCategories)
    : appliedCategories
}

const filtersUtils = {
  applyFilters
}

export default filtersUtils
