import { forwardRef } from 'react'
import { useMetrics } from '@hooks/useMetrics'

import './Pill.css'

interface PillProps {
  active?: boolean
  hasPending?: boolean
  past?: boolean
  label: string
  value: number
  translate: number
  ms: number
  changeRange: (newRange: number) => void
}

function buildClassName(
  props: Pick<PillProps, 'active' | 'hasPending' | 'past'>
) {
  const { active, hasPending, past } = props

  const className: string[] = []
  if (active) className.push('active')
  if (hasPending) className.push('pending')
  if (past) className.push('past')

  return className.join(' ')
}

const Pill = forwardRef<HTMLButtonElement, PillProps>(
  (
    { label, past, active, hasPending, value, translate, changeRange, ms },
    ref
  ) => {
    const { changeSlot, slot, pendingMetrics } = useMetrics()
    const className = buildClassName({ active, hasPending, past })

    return (
      <button
        style={{ transform: `translateX(${translate}px)` }}
        ref={ref}
        className={`pill ${className}`}
        type="button"
        disabled={Object.keys(pendingMetrics).length > 0}
        onClick={() => {
          const actual = new Date(ms)
          changeSlot({
            year: actual.getFullYear(),
            frequency: slot.frequency,
            value
          })
          changeRange(ms)
        }}
      >
        {label}
      </button>
    )
  }
)

Pill.displayName = 'Pill'

export default Pill
