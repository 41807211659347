import Metric from '@components/Intl/Metric/Metric'
import { useKpisWithMetadata, useKpisWithPendingData } from '@hooks/useApi'
import { useMetrics } from '@hooks/useMetrics'
import metricsUtils from '@utils/metrics'
import { useEffect, useMemo, useRef, useState } from 'react'
import utils from '@utils/utils'
import { Kpi } from 'src/server/types'
import { useOutletContext } from 'react-router'
import { useSelector } from 'react-redux'

import type { Factory } from 'src/types'

const { getMetricsWithValues, orderMetrics } = metricsUtils

export default function MetricsContainer() {
  const { reset } = useOutletContext<{ reset: number }>()
  const metrics = useKpisWithMetadata()
  const pendingKpis = useKpisWithPendingData()

  const selectedWarehouse = useSelector(
    (s: { warehouse: Factory }) => s.warehouse
  )

  const ref = useRef<HTMLLIElement>()

  const {
    slot,
    selectedFreq,
    addPendingMetric,
    changeSlotFreqToMonthly,
    changeSlotFreqToWeekly
  } = useMetrics()

  const filteredMetrics = useMemo(
    () => metricsUtils.filterByWarehouse(metrics, selectedWarehouse),
    [metrics, selectedWarehouse]
  )

  const orderedMetrics = useMemo(
    () => orderMetrics(filteredMetrics, selectedFreq),
    [filteredMetrics, selectedFreq]
  )

  const initialValues = useMemo(
    () => getMetricsWithValues(filteredMetrics, slot),
    [filteredMetrics, slot]
  )

  const [values, setValues] =
    useState<Record<number, Omit<Kpi, 'id'>>>(initialValues)

  const firstOfOtherFreqIndex =
    selectedFreq === 'weekly'
      ? orderedMetrics.findIndex((metric) => metric.kpiFrequency === 'monthly')
      : orderedMetrics.findIndex((metric) => metric.kpiFrequency === 'weekly')

  useEffect(() => {
    setValues(initialValues)
  }, [reset, initialValues])

  useEffect(() => {
    if (!ref.current) return
    const headerHeight = document
      .querySelector('header')
      .getBoundingClientRect().height
    const pillsHeight = document
      .querySelector('.pills')
      .getBoundingClientRect().height

    const handler = () => {
      const isUp =
        ref.current.getBoundingClientRect().top - (pillsHeight + headerHeight)
      if (isUp <= 0) {
        const call =
          selectedFreq === 'weekly'
            ? changeSlotFreqToMonthly
            : changeSlotFreqToWeekly
        call()
      } else {
        const call =
          selectedFreq === 'weekly'
            ? changeSlotFreqToWeekly
            : changeSlotFreqToMonthly
        call()
      }
    }

    const throttledHandler = utils.throttle(handler, 200)

    window.addEventListener('scroll', throttledHandler)
    return () => window.removeEventListener('scroll', throttledHandler)
  }, [changeSlotFreqToMonthly, changeSlotFreqToWeekly, selectedFreq])

  const handleChangeValue = (id: number, value: number) => {
    const copyValues = structuredClone(values)
    const element = copyValues[id]
    copyValues[id].value = value
    addPendingMetric(id, element)
    setValues(copyValues)
  }

  const findIsMissing = (id: number) => {
    const pendingKpi = pendingKpis.find((kpi) => kpi.id === id)
    if (!pendingKpi) return false
    const { missing_slots } = pendingKpi
    const slotFrequency = slot.frequency === 'monthly' ? 'M' : 'W'
    return missing_slots.includes(
      `${new Date().getFullYear()}-${slotFrequency}${slot.value}`
    )
  }

  return (
    <ul>
      {orderedMetrics.map((metric, index) => (
        <li key={metric.id} ref={index === firstOfOtherFreqIndex ? ref : null}>
          <Metric
            isMissingValue={findIsMissing(metric.id)}
            metric={metric}
            value={values[metric.id]?.value ?? 0}
            handleChangeValue={handleChangeValue}
          />
        </li>
      ))}
    </ul>
  )
}
