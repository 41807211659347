import { useCallback, useEffect, useRef, useState } from 'react'
import KpiChartRow from '../KpiChartRow/KpiChartRow'
import { useKpisWithMetadata } from '@hooks/useApi'
import { useSelector } from 'react-redux'
import { useKpiWithData } from '@hooks/useKpi'
import { type Factory } from 'src/types'
import metricsUtils from '@utils/metrics'
import { useFilters } from '@hooks/useFilters'

export default function KpiChartsContainer() {
  const kpis = useKpisWithMetadata()
  const { selectedCategory, selectedSubcategories, showAll } = useFilters()

  const [pinnedKpis, setPinnedKpis] = useState<number[]>([])

  useEffect(() => {
    const pinnedIds = metricsUtils.recoverPinnedKpis()
    const populatedKpis = kpis
      .filter((kpi) => pinnedIds.includes(kpi.id))
      .map((kpi) => kpi.id)
    setPinnedKpis(populatedKpis)
  }, [kpis])

  const updatePinnedKpi = useCallback((id: number) => {
    setPinnedKpis((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    )
  }, [])

  const selectedWarehouse = useSelector(
    (s: { warehouse: Factory }) => s.warehouse
  )

  const divRef = useRef<HTMLDivElement>()

  const kpisWithDataFundilusa = useKpiWithData(kpis, 'fundilusa')
  const kpisWithDataFundimaroc = useKpiWithData(kpis, 'fundimaroc')

  const [showedElements, setShowedElements] = useState<number[]>([])

  useEffect(() => {
    const elements = divRef.current?.children ?? []
    const elementsArr = Array.from(elements)

    const callback: IntersectionObserverCallback = (entries) => {
      setShowedElements((prev) => {
        let newState = [...prev]

        entries.forEach((entry) => {
          const index = elementsArr.indexOf(entry.target as HTMLElement)

          if (!entry.isIntersecting) {
            newState = newState.filter((el) => el !== index)
          } else if (!newState.includes(index)) {
            newState.push(index)
          }
        })

        return newState
      })
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px 0px 0px 0px'
    })

    elementsArr.forEach((element) => observer.observe(element))

    return () => {
      elementsArr.forEach((element) => observer.unobserve(element))
      setShowedElements([])
    }
  }, [selectedWarehouse, selectedCategory, selectedSubcategories, showAll])

  const showElements =
    selectedWarehouse === 'fundilusa'
      ? kpisWithDataFundilusa
      : kpisWithDataFundimaroc

  const orderedElements = metricsUtils.sortByPinned(
    structuredClone(showElements),
    pinnedKpis
  )

  return (
    <div ref={divRef}>
      {orderedElements.map(
        ({ actual, title, data, kpiFrequency, target, id }, index) => {
          return (
            <KpiChartRow
              id={id}
              key={id}
              name={title}
              actual={{
                target: actual ? (actual.targetValue ?? target) : target,
                value: actual ? actual.value : 0
              }}
              data={data}
              showed={showedElements.includes(index)}
              frequency={kpiFrequency}
              pinned={pinnedKpis.includes(id)}
              updatePinnedKpi={updatePinnedKpi}
            />
          )
        }
      )}
    </div>
  )
}
