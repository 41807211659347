import Metric from '@components/Intl/Metric/Metric'
import { CompleteKpi } from 'src/types'

const fallbackMetric: CompleteKpi = {
  title: '',
  category: '',
  formula: '',
  history: [],
  isNatural: true,
  isNegative: false,
  isVisible: true,
  kpiFrequency: 'monthly',
  subcategory: '',
  target: 0,
  unit: 'kg',
  warehouse: null,
  id: 12
}

export default function MetricsContainerFallback() {
  return (
    <ul>
      <li>
        <Metric
          isMissingValue={false}
          metric={fallbackMetric}
          value={0}
          handleChangeValue={() => null}
          fallback
        />
      </li>
      <li>
        <Metric
          isMissingValue={false}
          metric={fallbackMetric}
          value={0}
          handleChangeValue={() => null}
          fallback
        />
      </li>
      <li>
        <Metric
          isMissingValue={false}
          metric={fallbackMetric}
          value={0}
          handleChangeValue={() => null}
          fallback
        />
      </li>
      <li>
        <Metric
          isMissingValue={false}
          metric={fallbackMetric}
          value={0}
          handleChangeValue={() => null}
          fallback
        />
      </li>
    </ul>
  )
}
