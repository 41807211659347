import BarChartContainer from '@components/ChartsContainers/BarChart/BarChartContainer'
import GuageContainer from '@components/ChartsContainers/Guage/GuageContainer'
import { Icons } from '@static/icons'
import { FormattedMessage } from 'react-intl'

import '../KpiChartRow.css'

export default function KpiChartRowFallback() {
  return (
    <div className="kpi-entry">
      <div className="pin" role="button" aria-label="Pin element">
        <img src={Icons.pushpinIcon.source} alt={Icons.pushpinIcon.alt} />
        <span>
          <FormattedMessage id={'overview.guage.pin'} />
        </span>
      </div>

      <article>
        <GuageContainer
          moved={false}
          target={20}
          value={10}
          name={'Loading'}
          pinned={false}
        />
        <BarChartContainer data={[]} frequency={'monthly'} />
      </article>
    </div>
  )
}
