import { useState } from 'react'

export function useMenu() {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  return {
    openMenu,
    setOpenMenu
  }
}
