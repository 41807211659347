import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { useMetrics } from '@hooks/useMetrics'

export default function Metrics() {
  const { resetPendingMetrics } = useMetrics()

  const handleClick = () => setTimeout(resetPendingMetrics, 0)

  return (
    <button onClick={handleClick} className="metrics-save" type="submit">
      <FormattedMessage id="header.metrics.save" />
    </button>
  )
}
