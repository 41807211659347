import Pill from '../Pill/Pill'
import { useIntl } from 'react-intl'
import { useKpisWithPendingData } from '@hooks/useApi'
import { useMetrics } from '@hooks/useMetrics'
import timeUtils from '@utils/time'

interface WeeksProps {
  middle: number
  radius: number
  msPerPx: number
  changeRange: (newRange: number) => void
}

export default function Weeks({
  msPerPx,
  radius,
  middle,
  changeRange
}: WeeksProps) {
  const { slot } = useMetrics()
  const { year } = slot
  const actualWeek = slot.value

  const intl = useIntl()
  const pendingKpis = useKpisWithPendingData()

  const weeks = timeUtils.getWeeksRange(
    middle - radius * 2,
    middle + radius * 2
  )

  const weeklyPendingKpis = pendingKpis.filter(
    (pending) => pending.kpiFrequency === 'weekly'
  )

  const pendingWeeks = weeklyPendingKpis
    .map(({ missing_slots }) => missing_slots)
    .flat()

  return weeks.map((week) => {
    const weekNumber = timeUtils.getWeekByMs(week)
    const isActive = actualWeek === weekNumber

    const translate = (week - middle) / msPerPx

    return (
      <Pill
        changeRange={changeRange}
        ms={week}
        translate={translate}
        value={weekNumber}
        label={intl.formatMessage(
          { id: 'pills.weeks.label' },
          { value: weekNumber }
        )}
        key={week}
        past={actualWeek > weekNumber}
        active={isActive}
        hasPending={pendingWeeks.includes(`${year}-W${weekNumber}`)}
      />
    )
  })
}
