import timeUtils from './time'

import type {
  CompleteKpi,
  Factory,
  Frequency,
  KpiWithData,
  Slot
} from 'src/types'
import type { HomeResponse, Kpi } from 'src/server/types'

function getMetricsWithValues(
  metrics: CompleteKpi[],
  slot: Slot
): Record<number, Omit<Kpi, 'id'>> {
  const currentSlot = `${slot.year}-${slot.frequency === 'monthly' ? 'M' : 'W'}${slot.value}`
  return metrics.reduce<Record<number, Omit<Kpi, 'id'>>>(
    (historyRecord, metric) => {
      const currentHistory = metric.history.find(
        (history) => history.slot === currentSlot
      )

      historyRecord[metric.id] = currentHistory || {
        metadataId: metric.id,
        slot: currentSlot,
        value: 0,
        targetValue: metric.target
      }

      return historyRecord
    },
    {}
  )
}

function orderMetrics<
  T extends { kpiFrequency: Frequency; warehouse: string | null }
>(metrics: T[], order: Frequency): T[] {
  const sortedMetrics = structuredClone(metrics).sort((metricA, metricB) => {
    // First, sort by kpiFrequency (prioritizing those matching 'order')
    if (metricA.kpiFrequency === order && metricB.kpiFrequency !== order)
      return -1
    if (metricA.kpiFrequency !== order && metricB.kpiFrequency === order)
      return 1

    // If both have the same kpiFrequency, sort by warehouse (prioritizing null values)
    if (metricA.warehouse === null && metricB.warehouse !== null) return -1
    if (metricA.warehouse !== null && metricB.warehouse === null) return 1

    // If both have the same kpiFrequency and both have or don't have a warehouse, maintain the current order
    return 0
  })

  return sortedMetrics
}

function getMetricHistoryBySlot(kpi: CompleteKpi): Kpi | null {
  const date = new Date()
  const year = date.getFullYear()

  let template = ''

  if (kpi.kpiFrequency === 'monthly') {
    const month = date.getMonth()
    template = `${year}-M${month + 1}`
  } else {
    const week = timeUtils.getWeekNumber(new Date())
    template = `${year}-W${week}`
  }

  const historyEntry = kpi.history.find((history) => history.slot === template)

  return historyEntry ?? null
}

function getMetricValueBySlot(kpi: CompleteKpi): Kpi {
  const historyEntry = getMetricHistoryBySlot(kpi)

  if (!historyEntry) return null

  return historyEntry
}

function savePinned(newItemId: number) {
  const prevData =
    JSON.parse(window.localStorage.getItem('pinnedMetrics')) ?? []
  const newData = [...prevData, newItemId]
  window.localStorage.setItem('pinnedMetrics', JSON.stringify(newData))
}

function removePinned(removeItemId: number) {
  const prevData =
    (JSON.parse(window.localStorage.getItem('pinnedMetrics')) as number[]) ?? []
  const newData = prevData.filter((data) => data !== removeItemId)
  window.localStorage.setItem('pinnedMetrics', JSON.stringify(newData))
}

function recoverPinnedKpis(): number[] {
  const data = JSON.parse(window.localStorage.getItem('pinnedMetrics')) ?? []
  return data
}

function filterByWarehouse(
  metrics: HomeResponse,
  selectedWarehouse: Factory
): CompleteKpi[] {
  return metrics.filter((kpi) =>
    typeof kpi.warehouse === 'string'
      ? selectedWarehouse === kpi.warehouse.toLowerCase()
      : true
  )
}

function sortByPinned(
  data: KpiWithData[],
  pinnedItems: number[]
): KpiWithData[] {
  data.sort((a, b) => {
    const isInPinnedA = pinnedItems.includes(a.id)
    const isInPinnedB = pinnedItems.includes(b.id)

    if (isInPinnedA && !isInPinnedB) return -1
    if (!isInPinnedA && isInPinnedB) return 1
    return 0
  })
  return data
}

const metricsUtils = {
  getMetricValueBySlot,
  getMetricHistoryBySlot,
  getMetricsWithValues,
  orderMetrics,
  savePinned,
  removePinned,
  filterByWarehouse,
  recoverPinnedKpis,
  sortByPinned
}

export default metricsUtils
