import timeUtils from '@utils/time'
import { createContext, ReactNode, useCallback, useState } from 'react'
import { Kpi } from 'src/server/types'
import type { Factory, Frequency, Slot } from 'src/types'

const initialSlot: Slot = {
  frequency: 'weekly',
  value: timeUtils.getWeekNumber(new Date()),
  year: new Date().getFullYear()
}

type PendingMetrics = Record<number, Omit<Kpi, 'id'>>

interface MetricsContextValues {
  addPendingMetric: (key: number, value: Omit<Kpi, 'id'>) => void
  resetPendingMetrics: () => void
  changeSlot: (newSlot: Slot) => void
  changeSlotValue: (newValue: number) => void
  changeSlotFreqToWeekly: () => void
  changeSlotFreqToMonthly: () => void
  changeFreq: () => void
  pendingMetrics: PendingMetrics
  slot: Slot
  selectedFreq: Frequency
}

export const MetricsContext = createContext<MetricsContextValues>({
  addPendingMetric: () => null,
  resetPendingMetrics: () => null,
  changeSlot: () => null,
  changeSlotValue: () => null,
  changeSlotFreqToWeekly: () => null,
  changeSlotFreqToMonthly: () => null,
  changeFreq: () => null,
  selectedFreq: 'weekly',
  pendingMetrics: [],
  slot: initialSlot
})

interface MetricsContextProviderProps {
  children: ReactNode
}

export default function MetricsContextProvider({
  children
}: MetricsContextProviderProps) {
  const [pendingMetrics, setPendingMetrics] = useState<PendingMetrics>({})
  const [selectedFreq, setSelectedFreq] = useState<Frequency>('weekly')

  const [slot, setSlot] = useState<Slot>(initialSlot)

  const changeFreq = useCallback(
    () =>
      setSelectedFreq((prev) => (prev === 'monthly' ? 'weekly' : 'monthly')),
    []
  )

  const changeSlot = useCallback((newSlot: Slot) => setSlot(newSlot), [])

  const changeSlotValue = useCallback(
    (newValue: number) => setSlot((prev) => ({ ...prev, value: newValue })),
    []
  )

  const changeSlotFreqToWeekly = useCallback(
    () =>
      setSlot((prev) => {
        if (prev.frequency === 'weekly') return prev
        return {
          ...prev,
          frequency: 'weekly',
          value: timeUtils.getWeekNumber(new Date())
        }
      }),
    []
  )

  const changeSlotFreqToMonthly = useCallback(
    () =>
      setSlot((prev) => {
        if (prev.frequency === 'monthly') return prev
        return {
          ...prev,
          frequency: 'monthly',
          value: timeUtils.getMonthNumber(new Date())
        }
      }),
    []
  )

  const addPendingMetric = useCallback(
    (key: number, value: Omit<Kpi, 'id'>) =>
      setPendingMetrics((prev) => ({ ...prev, [key]: value })),
    []
  )

  const resetPendingMetrics = useCallback(() => setPendingMetrics([]), [])

  return (
    <MetricsContext.Provider
      value={{
        addPendingMetric,
        resetPendingMetrics,
        changeSlot,
        changeSlotValue,
        changeSlotFreqToMonthly,
        changeSlotFreqToWeekly,
        changeFreq,
        selectedFreq,
        pendingMetrics,
        slot
      }}
    >
      {children}
    </MetricsContext.Provider>
  )
}
