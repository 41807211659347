import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { useNavigate } from 'react-router-dom'
import AnimatedBg from '@components/AnimatedBg/AnimatedBg'

import logo from '@static/logo/fundilusa-logo.svg'
import './Login.css'

export default function Login() {
  const navigate = useNavigate()
  return (
    <main id="login">
      <AnimatedBg />
      <div className="foreground">
        <img srcSet={logo} alt="Fundilusa logo" className="logo" />
        <h1>
          <FormattedMessage id="login.title" />
        </h1>
        <button onClick={() => navigate('/')}>
          <FormattedMessage id="login" />
        </button>
      </div>
    </main>
  )
}
