import chartsUtils from '@utils/charts'

interface SemiCircularGaugeProps {
  sections?: number
  value: number
  target: number
}

export default function SemiCircularGauge({
  value,
  target,
  sections = 45
}: SemiCircularGaugeProps) {
  const radius = 110
  const sectionAngle = 240 / sections

  const end = Math.round(chartsUtils.converToSections(value, target, sections))

  const getColor = (index) => {
    const percentage = chartsUtils.convertTo240(index, sections)

    return `hsl(${percentage}, 100%, 50%)`
  }

  const generateSections = () => {
    const rects = []
    for (let i = 0; i < sections; i++) {
      const angle = -120 + i * sectionAngle

      rects.push(
        <rect
          key={i}
          x={radius}
          y="0"
          width="5"
          height="20"
          fill={i <= end ? getColor(i) : '#272B4F'}
          transform={`rotate(${angle} ${radius} ${radius})`}
        />
      )
    }
    return rects
  }

  return (
    <svg
      viewBox="0 0 220 170"
      // preserveAspectRatio="xMidYmid meet"
      style={{
        aspectRatio: '3 / 2'
      }}
    >
      {generateSections()}
      {!value || value / 100 < 1 ? (
        <text
          x={radius}
          y={radius - 20}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize="48px"
          fill="#262850"
        >
          0
          <tspan fill="#FFF" alignmentBaseline="middle" textAnchor="middle">
            {value ?? 0}
          </tspan>
        </text>
      ) : (
        <text
          x={radius}
          y={radius - 20}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize="48px"
          fill="#FFF"
        >
          {value ?? 0}
        </text>
      )}

      <g transform={`translate(${radius - 24}, ${radius + 35})`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle
            cx="9.01724"
            cy="9.01724"
            r="8.01724"
            stroke="white"
            strokeWidth="1.5"
          />
          <circle
            cx="9.01721"
            cy="9.01741"
            r="5.22864"
            stroke="white"
            strokeWidth="1.5"
          />
          <circle
            cx="9.01767"
            cy="9.01758"
            r="2.44003"
            stroke="white"
            strokeWidth="1.5"
          />
        </svg>

        <text
          x="30"
          y="14"
          alignmentBaseline="middle"
          textAnchor="start"
          fontSize="18px"
          fill="white"
        >
          {target}
        </text>
      </g>
    </svg>
  )
}
