import { Kpi } from 'src/server/types'
import timeUtils from './time'
import { Frequency } from 'src/types'

export type MergedChartData = Pick<Kpi, 'value' | 'targetValue'> & {
  year: number
  time: number
}

function convertRange(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
): number {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

function convertTo240(value: number, sections: number): number {
  return convertRange(value, 0, sections, 0, 240)
}

function converToSections(value: number, maxValue: number, sections: number) {
  return convertRange(value, 0, maxValue, 0, sections)
}

function mergeData(
  data: Kpi[],
  defaultTarget: number,
  frequency: Frequency
): MergedChartData[] {
  const units =
    frequency === 'weekly'
      ? timeUtils.generateWeeksAYearAgo()
      : timeUtils.getMonthsAYearAgo()

  return units.map(({ year, value }) => {
    const slot = `${year}-${frequency === 'weekly' ? 'W' : 'M'}${value.toString()}`

    const found = data.find((d) => d.slot === slot)

    return found
      ? {
          value: found.value,
          targetValue: found.targetValue ?? defaultTarget,
          year,
          time: value
        }
      : { value: 0, targetValue: defaultTarget, year, time: value }
  })
}

function getBestChartLabels(maxValue: number) {
  const bestTargets = [10, 20, 50, 100, 200, 300, 500, 1000]
  const bestTarget = bestTargets.find((target) => maxValue <= target)
  return bestTarget
}

function getMonthLabels(month: number, year: number) {
  const date = new Date(year, month - 1)
  return timeUtils.getMonthName('es', date, 'short')
}

const chartsUtils = {
  convertRange,
  convertTo240,
  converToSections,
  mergeData,
  getBestChartLabels,
  getMonthLabels
}

export default chartsUtils
