import Pill from '../Pill/Pill'
import timeUtils from '@utils/time'

import { useSelector } from 'react-redux'
import { useKpisWithPendingData } from '@hooks/useApi'
import { useMetrics } from '@hooks/useMetrics'

interface MonthsProps {
  msPerPx: number
  middle: number
  radius: number
  changeRange: (center: number) => void
}

export default function Months({
  msPerPx,
  middle,
  radius,
  changeRange
}: MonthsProps) {
  const { slot } = useMetrics()
  const { year } = slot
  const actualMonth = slot.value

  const pendingKpis = useKpisWithPendingData()
  const locale = useSelector((s: { intl: { locale: string } }) => s.intl.locale)

  const months = timeUtils.getMonthsRange(
    middle - radius * 2,
    middle + radius * 2
  )

  const monthPendingKpis = pendingKpis.filter(
    (pending) => pending.kpiFrequency === 'monthly'
  )

  const pendingMonths = monthPendingKpis
    .map(({ missing_slots }) => missing_slots)
    .flat()

  return months.map((month, index) => {
    const monthNumber = timeUtils.getMonthByMs(month)

    const isActive = actualMonth === monthNumber

    const monthName = timeUtils.getMonthName(locale, new Date(month), 'long')

    const translate = (month - middle) / msPerPx

    return (
      <Pill
        ms={month}
        changeRange={changeRange}
        translate={translate}
        value={monthNumber}
        label={monthName}
        key={index}
        past={actualMonth > monthNumber}
        active={isActive}
        hasPending={pendingMonths.includes(`${year}-M${monthNumber}`)}
      />
    )
  })
}
