import target from './target.svg'
import calendar from './calendar.svg'
import house from './house.svg'
import cross from './cross.svg'
import arrow from './arrow.svg'
import week from './week.svg'
import pushpin from './pushpin.svg'
import happy from './happy.svg'
import sad from './sad.svg'
import filters from './filters.svg'
import logout from './logout.svg'
import detachPushpin from './detach-pushpin.svg'

const targetIcon = {
  source: target,
  alt: 'Target icon'
}

const calendarIcon = {
  source: calendar,
  alt: 'Calendar icon'
}

const houseIcon = {
  source: house,
  alt: 'House icon'
}

const crossIcon = {
  source: cross,
  alt: 'Cross icon'
}

const arrowIcon = {
  source: arrow,
  alt: 'arrow icon'
}

const pushpinIcon = {
  source: pushpin,
  alt: 'pushpin icon'
}

const detachPushpinIcon = {
  source: detachPushpin,
  alt: 'detach pushpin icon'
}

const weekIcon = {
  source: week,
  alt: 'week icon'
}

const happyIcon = {
  source: happy,
  alt: 'happy icon'
}

const sadIcon = {
  source: sad,
  alt: 'sad icon'
}

const filtersIcon = {
  source: filters,
  alt: 'filters icon'
}

const logoutIcon = {
  source: logout,
  alt: 'logout icon'
}

const Icons = {
  targetIcon,
  calendarIcon,
  houseIcon,
  crossIcon,
  arrowIcon,
  weekIcon,
  pushpinIcon,
  happyIcon,
  sadIcon,
  filtersIcon,
  logoutIcon,
  detachPushpinIcon
}

export { Icons }
