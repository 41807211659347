import { Factory } from 'src/types'
import { changeWarehouse } from '../actions/warehouse'

const initialState: Factory = 'fundilusa'

export default (
  state: Factory = initialState,
  action: ReturnType<typeof changeWarehouse>
) => {
  switch (action.type) {
    case 'warehouse/change':
      return state === 'fundimaroc' ? 'fundilusa' : 'fundimaroc'
    default:
      return state
  }
}
