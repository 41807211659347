import SemiCircularGauge from '@components/Charts/Guage'
import { Icons } from '@static/icons'

import './GuageContainer.css'

interface GuageContainerProps {
  moved: boolean
  value: number
  target: number
  name: string
  pinned: boolean
}

export default function GuageContainer({
  moved,
  target,
  value,
  name,
  pinned
}: GuageContainerProps) {
  return (
    <section className={`guage-container ${moved ? 'moved' : ''}`}>
      <h2>{name}</h2>
      <SemiCircularGauge value={value} target={target} />
      {pinned ? (
        <img src={Icons.pushpinIcon.source} alt={Icons.pushpinIcon.alt} />
      ) : null}
    </section>
  )
}
