import {
  useFetch,
  useFetchCb,
  useFetchUncachedCb
} from '@gluedigital/ruse-fetch-extras'
import { HomeResponse, Kpi, PendingResponse } from 'src/server/types'

const baseApiUrl = process.env.API_URL || 'http://localhost:3000/api'

export function useKpisWithMetadata(): HomeResponse {
  const kpis = useFetch<HomeResponse>(`${baseApiUrl}/kpi/home`, undefined)

  return kpis
}

export function useRefreshKpis(): () => Promise<void> {
  const refresh = useFetchCb<void>()

  return () => refresh(`${baseApiUrl}/kpi/home`)
}

export function useKpisWithPendingData(): PendingResponse {
  const pendingKpis = useFetch<PendingResponse>(`${baseApiUrl}/kpi/pending`)

  return pendingKpis
}

export function useUpdateKpis(): (body: Omit<Kpi, 'id'>[]) => Promise<Kpi[]> {
  const fetch = useFetchUncachedCb()

  return (body) =>
    fetch(`${baseApiUrl}/kpi`, {
      method: 'POST',
      body
    })
}
