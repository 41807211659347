function throttle(func: (...args: unknown[]) => void, delay: number) {
  let lastCall = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCall < delay) return
    lastCall = now
    return func(...args)
  }
}

const utils = {
  throttle
}

export default utils
