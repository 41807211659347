import { Routes, Route } from 'react-router-dom'
import Dashboard from '@routes/Dashboard/Dashboard'
import Layout from '@components/Layout/Layout'
import DashboardLayout from '@components/Layout/DashboardLayout'
import Meta from '@components/Metas/Meta'
import Visualizer from './Overview/Overview'
import Login from './Login/Login'

const App = () => (
  <>
    <Meta />
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route element={<Dashboard />} index />
      </Route>
      <Route element={<Layout />}>
        <Route path="/overview" element={<Visualizer />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  </>
)

export default App
