import { useFiltersData } from '@hooks/useFilters'
import { createContext, ReactNode } from 'react'

interface FiltersContextValues {
  selectedCategory: string
  selectedSubcategories: string[]
  showAll: boolean
  changeSelectedCategory: (category: string) => void
  handleSelectedSubcategory: (newCategory: string) => void
  changeSelectedSubcategories: (subcategories: string[]) => void
  toggleShow: () => void
}

export const FiltersContext = createContext<FiltersContextValues | null>(null)

interface FiltersContextProviderProps {
  children: ReactNode
}

export default function FiltersContextProvider({
  children
}: FiltersContextProviderProps) {
  const {
    changeSelectedCategory,
    handleSelectedSubcategory,
    changeSelectedSubcategories,
    toggleShow,
    selectedCategory,
    showAll,
    selectedSubcategories
  } = useFiltersData()

  return (
    <FiltersContext.Provider
      value={{
        selectedCategory,
        selectedSubcategories,
        showAll,
        changeSelectedSubcategories,
        changeSelectedCategory,
        handleSelectedSubcategory,
        toggleShow
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
