interface Category {
  name: string
  subcategories: string[]
}

export const categoriesData: Category[] = [
  {
    name: 'production',
    subcategories: [
      'foundry',
      'machining',
      'polishing',
      'expeditions',
      'rrhh',
      'maintenance',
      'models'
    ]
  },
  {
    name: 'quality',
    subcategories: []
  },
  {
    name: 'security',
    subcategories: []
  }
]
