import timeUtils from '@utils/time'
import Pill from '../Pill/Pill'
import { useIntl } from 'react-intl'
import { useMetrics } from '@hooks/useMetrics'

interface WeeksProps {
  middle: number
  radius: number
  msPerPx: number
  changeRange: (newRange: number) => void
}

export default function WeeksFallback({
  changeRange,
  middle,
  msPerPx,
  radius
}: WeeksProps) {
  const { slot } = useMetrics()
  const intl = useIntl()

  const actualWeek = slot.value

  const weeks = timeUtils.getWeeksRange(
    middle - radius * 2,
    middle + radius * 2
  )

  return weeks.map((week) => {
    const weekNumber = timeUtils.getWeekByMs(week)
    const isActive = actualWeek === weekNumber

    const translate = (week - middle) / msPerPx

    return (
      <Pill
        changeRange={changeRange}
        ms={week}
        translate={translate}
        value={weekNumber}
        label={intl.formatMessage(
          { id: 'pills.weeks.label' },
          { value: weekNumber }
        )}
        key={week}
        past={actualWeek > weekNumber}
        active={isActive}
        hasPending={false}
      />
    )
  })
}
