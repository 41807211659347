import BarChart from '@components/Charts/BarChart'
import { Frequency } from 'src/types'
import chartsUtils, { MergedChartData } from '@utils/charts'
import { useEffect, useRef } from 'react'
import './BarChartContainer.css'

interface BarChartContainerProps {
  data: MergedChartData[]
  frequency: Frequency
}

export default function BarChartContainer({
  data,
  frequency
}: BarChartContainerProps) {
  const maxTargets = Math.max(...data.map((entry) => entry.targetValue))
  const maxValues = Math.max(...data.map((entry) => entry.value))
  const max = Math.max(maxTargets, maxValues)
  const target = chartsUtils.getBestChartLabels(max)
  const divRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const div = divRef.current
    if (!div) return
    div.scrollLeft = div.scrollWidth
  }, [])

  return (
    <section className="barchart-container">
      <div>
        <span>{target}</span>
        <span>{target / 2}</span>
        <span>0</span>
      </div>
      <div className="chart-wrapper" ref={divRef}>
        <BarChart
          data={data}
          prev={null}
          maxTarget={target}
          frequency={frequency}
        />
      </div>
    </section>
  )
}
