import { FormattedMessage } from '@components/Intl/FormattedMessage'
import KpiChartsContainer from './KpiChartsContainer/KpiChartsContainer'
import { Suspense, useState } from 'react'
import FiltersContainer from '@components/Filters/FIltersContainer/FIltersContainer'
import FiltersContextProvider from 'src/context/FiltersContext'
import ShowFilters from '@components/Filters/ShowFilters/ShowFilters'
import KpiChartContainerFallback from './KpiChartsContainer/KpiChartContainerFallback'

import './Overview.css'

export default function Visualizer() {
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  return (
    <FiltersContextProvider>
      <main id="charts">
        <div className="container">
          <h1>
            <FormattedMessage id="visualizer.heading" />
          </h1>
          <ShowFilters openDialog={() => setOpenDialog(true)} />
          <Suspense fallback={<KpiChartContainerFallback />}>
            <KpiChartsContainer />
          </Suspense>
          {openDialog ? (
            <FiltersContainer
              open={openDialog}
              closeDialog={() => setOpenDialog(false)}
            />
          ) : null}
        </div>
      </main>
    </FiltersContextProvider>
  )
}
