import KpiChartRowFallback from '../KpiChartRow/Fallback/KpiChartRowFallback'

export default function KpiChartContainerFallback() {
  return (
    <div>
      <KpiChartRowFallback />
      <KpiChartRowFallback />
      <KpiChartRowFallback />
    </div>
  )
}
