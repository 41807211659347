import CustomSelect from '@components/CustomSelect/CustomSelect'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Icons } from '@static/icons'
import { useState } from 'react'
import { useIntl } from 'react-intl'

function LabelComponent({ label }: { label: string }) {
  return (
    <div>
      <span>
        <FormattedMessage
          id="menu.language"
          values={{
            value: <span>{label}</span>
          }}
        />
      </span>
      <img src={Icons.arrowIcon.source} alt={Icons.arrowIcon.alt} />
    </div>
  )
}

function OptionComponent({
  option,
  selected
}: {
  option: { value: string; label: string }
  selected: boolean
}) {
  return <span className={selected ? 'selected' : ''}>{option.label}</span>
}

export default function SelectLanguage() {
  const [language, setLanguage] = useState<string>('es')
  const intl = useIntl()

  const options: { value: string; label: string }[] = [
    { value: 'es', label: intl.formatMessage({ id: 'menu.language.spanish' }) },
    {
      value: 'mc',
      label: intl.formatMessage({ id: 'menu.language.morocco' })
    },
    {
      value: 'pt',
      label: intl.formatMessage({ id: 'menu.language.portugal' })
    },
    {
      value: 'default',
      label: intl.formatMessage({ id: 'menu.language.default' })
    }
  ]

  const handleChange = (value: string) => {
    setLanguage(value)
  }

  return (
    <CustomSelect
      LabelComponent={
        <LabelComponent
          label={options.find((option) => option.value === language).label}
        />
      }
      OptionComponent={OptionComponent}
      onChange={{
        add: handleChange
      }}
      options={options}
      value={language}
    />
  )
}
