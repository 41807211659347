import { ChangeEvent } from 'react'
import { Icons } from '@static/icons'
import { useMetrics } from '@hooks/useMetrics'
import { CompleteKpi } from 'src/types'
import { useIntl } from 'react-intl'
import { UnitsIcons } from '@static/icons/units'

import './Metric.css'

interface MetricProps {
  metric: CompleteKpi
  value: number
  isMissingValue: boolean
  handleChangeValue: (id: number, newValue: number) => void
  fallback?: boolean
}

export default function Metric({
  metric,
  value,
  isMissingValue,
  fallback = false,
  handleChangeValue
}: MetricProps) {
  const { slot } = useMetrics()

  const intl = useIntl()

  const {
    category,
    kpiFrequency,
    target: metricTarget,
    title,
    id,
    unit
  } = metric

  const icon = UnitsIcons[unit] ?? UnitsIcons.units

  const currentSlot = `${slot.year}-${slot.frequency === 'monthly' ? 'M' : 'W'}${slot.value}`
  const history = metric.history.find((entry) => entry.slot === currentSlot)

  const target = history?.targetValue ?? metricTarget
  const initialValue = history?.value

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    handleChangeValue(id, +value)
  }

  const showStatus = history !== undefined

  return (
    <article
      className={`metric ${isMissingValue ? 'missing' : ''} ${showStatus ? 'show-status' : ''} ${fallback ? 'fallback' : ''}`}
    >
      <header>
        <div>
          <label htmlFor={id.toString()}>{title}</label>
          <p>{category}</p>
        </div>
        <div
          className={`icon ${kpiFrequency === 'weekly' ? 'week' : 'month'}`}
          role="img"
          aria-label={Icons.weekIcon.alt}
        />
      </header>
      <div>
        <div>
          <div className="target-container">
            <img src={Icons.targetIcon.source} alt={Icons.targetIcon.alt} />
            <span>{target === 0 ? '-' : target}</span>
          </div>
          <div
            className={`result-img-container ${initialValue ? (value < target ? 'sad' : 'happy') : ''}`}
          >
            <img
              src={
                value < target ? Icons.sadIcon.source : Icons.happyIcon.source
              }
              alt={value < target ? Icons.sadIcon.alt : Icons.happyIcon.alt}
            />
          </div>
        </div>
        <div className="input-container">
          <input
            id={id.toString()}
            name={title}
            type="number"
            step="0.01"
            placeholder="0"
            min={0}
            aria-label={`${title} input`}
            aria-describedby={intl.formatMessage({ id: 'metric.input.aria' })}
            value={value === 0 ? '' : value}
            onChange={handleChange}
          />
          <img src={icon} alt={`${unit} icon`} />
        </div>
      </div>
    </article>
  )
}
